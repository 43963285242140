import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, catchError, of } from 'rxjs';

import { ConfigService } from '@services/config.service';

import { customException } from '@utils/capture-exception.util';

import { Stratum } from './arch-significant.interface';

/**
 * Сервис для работы слоями.
 * @class
 */
@Injectable({ providedIn: 'root' })
export class ArchSignificantService {
  /**
   * Функция, которая внедряет зависимость ConfigService в переменную configService
   * @param {ConfigService} ConfigService - сервис для работы с конфигурацией
   * @returns {void}
   */
  #configService = inject(ConfigService);

  /**
   * Внедряет зависимость HttpClient в переменную httpClient.
   *
   * @param {HttpClient} inject - Объект, который будет внедрен в переменную httpClient
   */
  #httpClient = inject(HttpClient);

  /**
   * Получает события из API и возвращает Observable с массивом объектов Stratum
   * @returns {Observable<Stratum[]>} Observable с массивом объектов Stratum
   */
  getEvents(): Observable<Stratum[]> {
    return this.#httpClient
      .get<Stratum[]>('/meta_api/event/', {
        params: this.#configService.getValue('META_API_STAND') ? { stand: this.#configService.getValue('META_API_STAND') } : undefined,
      })
      .pipe(
        catchError((err) => {
          customException({ msg: 'getEvents', err });

          return of([]);
        }),
      );
  }
}
