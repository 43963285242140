import { withDevtools } from '@angular-architects/ngrx-toolkit';
import { Injectable, computed, inject } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import { patchState, signalStore, withState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { pipe, switchMap, tap } from 'rxjs';

import { deleteObjectProperty } from '@utils/immutable.util';

import { BuildingCard } from '../../../3dmodels/models3d.interface';
import { Models3dStoreService } from '../../../3dmodels/store/models3d.store.service';
import { Status } from '../../../../../enums/status.enum';
import { Question, Stratum } from '../arch-significant.interface';
import { ArchSignificantService } from '../arch-significant.service';
import { ArchSignificantState, Event, Events } from './arch-significant.store.type';

/**
 * Сервис ArchSignificantStore обеспечивает управление состоянием данных значимых архитектурных объектов.
 * Он расширяет функциональность `signalStore` для поддержки разработки с использованием инструментов отладки и
 * управления состоянием. Стартовые данные состояния включают статусы, ошибки, данные о слоях, категориях, вопросах,
 * моделях и событиях.
 */
@Injectable({ providedIn: 'root' })
export class ArchSignificantStoreService extends signalStore(
  { protectedState: false },
  withDevtools('stratums'),
  withState<ArchSignificantState>({
    status: Status.UNINITIALIZED,
    error: null,
    stratums: {},
    stratumsIds: [],
    categories: [],
    questions: {},
    questionsIds: [],
    models: {},
    events: {} as Events,
    expanded: {},
  }),
) {
  /**
   * #archSignificantService
   *
   * @description
   * Переменная `#archSignificantService` представляет собой внедрение зависимости
   * сервиса ArchSignificantService. Этот сервис используется для обработки операций,
   * связанных с архитектурно значимой функциональностью в приложении. Внедрение
   * осуществляется при помощи механизма зависимости, что позволяет управлять
   * функциональностью приложения более гибким и модульным образом.
   *
   * @type {ArchSignificantService}
   */
  readonly #archSignificantService = inject(ArchSignificantService);
  /**
   * Переменная `buildings` представляет собой карту сущностей 3D моделей зданий.
   * Она получается путем инъекции службы `Models3dStoreService` и используется для
   * управления и доступа к различным моделям 3D зданий.
   */
  readonly #buildings = inject(Models3dStoreService).models3dEntityMap;

  /**
   * Переменная, указывающая на то, находится ли объект в неинициализированном состоянии.
   * Тип данных: логическое значение – истина, если статус объекта равен Status.UNINITIALIZED,
   * в противном случае – ложь.
   */
  readonly isUninitialized = computed(() => this.status() === Status.UNINITIALIZED);
  /**
   * @type {boolean}
   * @description
   * Переменная isLoaded является вычисляемым свойством, которое возвращает булево значение.
   * Она показывает, загружен ли объект или находится в состоянии загрузки.
   * Возвращает true, если текущий статус объекта отличается от Status.LOADING, иначе возвращает false.
   */
  readonly isLoaded = computed(() => this.status() !== Status.LOADING);

  /**
   * @description
   * Переменная `getData` представляет собой реактивный метод, который инициирует процесс асинхронного получения данных.
   * В начале выполнения устанавливает состояние загрузки. Затем делает запрос на получение событий
   * с сервиса `#archSignificantService`.
   * Полученные данные обрабатываются в виде массива, содержащего страты данных.
   * Каждая стратум добавляется в общий массив с последующей фильтрацией данных.
   * После успешного получения данных и их обработки обновляет состояние с полученными данными.
   * В случае возникновения ошибки выводит её в консоль.
   * В завершение выполнения устанавливает состояние как загруженное, вне зависимости от того,
   * произошла ли ошибка.
   */
  private readonly getData = rxMethod<void>(
    pipe(
      tap(() => patchState(this, { status: Status.LOADING })),
      switchMap(() =>
        this.#archSignificantService.getEvents().pipe(
          tapResponse({
            next: (data) => {
              const stratumsData = data.reduce(
                (acc, stratum) => {
                  acc.stratums[stratum.id] = stratum;
                  acc.stratumsIds.push(stratum.id);
                  stratum.questions.forEach((question) => {
                    acc.questions[question.id] = question;
                    question.models.forEach((model) => {
                      if (this.#buildings()[model.guid]) {
                        acc.models[model.guid] = model;
                      }
                    });
                  });

                  return {
                    ...acc,
                    ...this.filterIteration(
                      { categories: acc.categories, events: acc.events, questionsIds: acc.questionsIds },
                      stratum,
                      '',
                      this.#buildings(),
                    ),
                  };
                },
                {
                  events: {} as Events,
                  questionsIds: [],
                  categories: [],
                  stratums: {},
                  stratumsIds: [],
                  questions: {},
                  models: {},
                } as Pick<
                  ArchSignificantState,
                  'categories' | 'events' | 'models' | 'questions' | 'questionsIds' | 'stratums' | 'stratumsIds'
                >,
              );

              patchState(this, stratumsData);
            },
            error: console.error,
            finalize: () => patchState(this, { status: Status.LOADED }),
          }),
        ),
      ),
    ),
  );

  /**
   * Загружает события, если они еще не были инициализированы.
   *
   * @return {void} Никакого значения не возвращает.
   */
  loadEventsAction(): void {
    this.isUninitialized() && this.getData();
  }

  /**
   * Переключает состояние указанного свойства в объекте expanded.
   * Если свойство уже существует в объекте expanded, оно будет удалено.
   * Если свойство отсутствует, оно будет добавлено со значением true.
   *
   * @param {string} prop Имя свойства, состояние которого нужно переключить.
   * @return {void} Этот метод ничего не возвращает.
   */
  toggle(prop: string): void {
    let expanded = this.expanded();
    if (expanded[prop]) {
      expanded = deleteObjectProperty(expanded, prop);
    } else {
      expanded = { ...expanded, [prop]: true };
    }

    patchState(this, { expanded });
  }

  /**
   * Этот метод фильтрует данные в соответствии с заданным значением и возвращает объект, содержащий категории, события и идентификаторы вопросов.
   *
   * @param value Строка, которая используется для фильтрации данных.
   * @return Объект, содержащий отфильтрованные категории, события и идентификаторы вопросов.
   */
  getFilteredData(value: string): Pick<ArchSignificantState, 'categories' | 'events' | 'questionsIds'> {
    return this.stratumsIds().reduce(
      (acc, stratumsId) => {
        const stratum = this.stratums()[stratumsId];

        return {
          ...acc,
          ...this.filterIteration(
            { categories: acc.categories, events: acc.events, questionsIds: acc.questionsIds },
            stratum,
            value,
            this.#buildings(),
          ),
        };
      },
      { events: {} as Events, questionsIds: [], categories: [] } as Pick<ArchSignificantState, 'categories' | 'events' | 'questionsIds'>,
    );
  }

  /**
   * Фильтрует итерацию на основе заданных параметров и обновляет состояние категорий, событий и идентификаторов вопросов.
   *
   * @param {Object} state - Объект состояния, содержащий категории, события и идентификаторы вопросов.
   * @param {Array} state.categories - Массив категорий.
   * @param {Object} state.events - Объект событий.
   * @param {Array} state.questionsIds - Массив идентификаторов вопросов из состояния.
   * @param {Stratum} stratum - Стратум, содержащий вопросы, категорию и дату начала в формате ISO.
   * @param {string} [value=''] - Значение для фильтрации вопросов по имени или адресу моделей.
   * @param {Object} buildings - Объект данных о зданиях, ключи которого - строки, представляющие идентификатор здания.
   *
   * @return {Object} Возвращает обновленный объект состояния с категориями, событиями и идентификаторами вопросов.
   */
  private filterIteration(
    { categories, events, questionsIds: dataQuestionsIds }: Pick<ArchSignificantState, 'categories' | 'events' | 'questionsIds'>,
    stratum: Stratum,
    value = '',
    buildings: Record<string, BuildingCard>,
  ): Pick<ArchSignificantState, 'categories' | 'events' | 'questionsIds'> {
    const questionsNumbersLookupTable = stratum.questions.reduce<Record<Question['id'], number>>((acc, curr) => {
      acc[curr.id] = curr.number;
      return acc;
    }, {});

    let questionsIds = stratum.questions
      .reduce((acc, question) => {
        if (value) {
          if (
            buildings[question.models[0].guid] &&
            (question.name.toLowerCase().includes(value.toLowerCase()) ||
              question.models.some((model) => model.address.toLowerCase().includes(value.toLowerCase())))
          ) {
            acc.push(question.id);
          }
        } else {
          if (buildings[question.models[0].guid]) {
            acc.push(question.id);
          }
        }

        return acc;
      }, [] as string[])
      .sort((idPrev, idCurr) => questionsNumbersLookupTable[idPrev] - questionsNumbersLookupTable[idCurr]);

    if (!questionsIds.length) {
      return { categories, events, questionsIds: dataQuestionsIds };
    }

    if (!categories.includes(stratum.category)) {
      categories.push(stratum.category);
    }

    const date = new Date(stratum.start_datetime_msk).toLocaleDateString('ru-Ru');

    const event: Event = { date, isoDate: stratum.start_datetime_msk, questionsIds };

    if (!events[stratum.category]) {
      events[stratum.category] = [event];
    } else {
      const eventsList = events[stratum.category];

      const index = eventsList.findIndex((item) => item.date === date);

      if (index > -1) {
        events[stratum.category][index] = { ...eventsList[index], ...event };
      } else {
        events[stratum.category].push(event);
        events[stratum.category].sort((a, b) => new Date(b.isoDate).getTime() - new Date(a.isoDate).getTime());
      }
    }

    questionsIds = [...dataQuestionsIds, ...questionsIds];

    return { categories, events, questionsIds };
  }
}
